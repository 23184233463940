import { computed } from 'mobx';
import moment from 'moment';
import {
    Transaction,
    TransactionBrief,
    TransactionDirectionEnum,
} from 'src/generated-api-client';
import {
    bpmsResourcesManagerApi,
    transactionsApi,
} from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/TransactionsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type TransactionsFilter = {
    search?: string;
    direction?: TransactionDirectionEnum;
    status?: string[];
    type?: string[];
    createdAtFrom?: moment.Moment;
    createdAtTo?: moment.Moment;
    createdAt?: moment.Moment[];
    processedAtFrom?: moment.Moment;
    processedAtTo?: moment.Moment;
    processedAt?: moment.Moment[];
};

export class TransactionsStoreClass extends BasicStore<
    TransactionBrief | Transaction
> {
    itemDetailsFormKey =
        'embedded:deployment:forms/bo/bo-01-transaction-info.json';

    api: BasicStoreApi<TransactionBrief | Transaction> = {
        loadList: async (...args: any[]) => {
            const options = RequestHelper.getOptionsFromArgs(args);
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.findAllTransactions(
                    this.pager?.page,
                    this.pager.size,
                    InitialFilters.defaultSort,
                    this.filterCriteria.filter.type,
                    this.filterCriteria.filter.status,
                    this.filterCriteria.filter.createdAtFrom?.toISOString(true),
                    this.filterCriteria.filter.createdAtTo?.toISOString(),
                    this.filterCriteria.filter.processedAtFrom?.toISOString(
                        true,
                    ),
                    this.filterCriteria.filter.processedAtTo?.toISOString(),
                    this.filterCriteria.filter.search,
                    options,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },
        loadItemForm: (formKey: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(formKey, options),
            );
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.getTransaction(id, options),
            );
        },
    };

    filterCriteria: FilterCriteria<TransactionsFilter> = new FilterCriteria({});

    pager = new Pager();

    @computed get hasFilter() {
        return [
            this.filterCriteria.filter.direction,
            this.filterCriteria.filter.status,
            this.filterCriteria.filter.search,
            this.filterCriteria.filter.type,
            this.filterCriteria.filter.createdAtFrom,
            this.filterCriteria.filter.createdAtTo,
            this.filterCriteria.filter.processedAtFrom,
            this.filterCriteria.filter.processedAtTo,
        ].some((item) => Boolean(item));
    }
}

export const TransactionsStore = new TransactionsStoreClass();
