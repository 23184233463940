import _ from 'lodash';
import { action, computed } from 'mobx';
import { processDefinitionManagerApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProcessStoreClass extends BasicStore<any> {
    api: BasicStoreApi<any> = {
        loadItem: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                processDefinitionManagerApi.getStartFormVariables(
                    this.key,
                    options,
                ),
            );
        },
        loadItemForm: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                processDefinitionManagerApi.getStartForm(this.key, options),
            );
        },
        updateItem: (key, data, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                processDefinitionManagerApi.submitStartFrom(
                    String(key),
                    data,
                    options,
                ),
            );
        },
    };

    constructor(
        private key: string,
        private context?: any,
        private useContextWrapper: boolean = true,
    ) {
        super();
    }

    @action async submit(data: any = {}) {
        const contextInterface = this.useContextWrapper
            ? { context: this.context }
            : this.context;
        await this.update(
            this.key,
            !this.context ? data : { ...data, ...contextInterface },
        );

        return this.updateItemLoader.data;
    }

    getInitialVariables() {
        const clonedProcessVariables = _.cloneDeep(this.itemLoader.data);

        const contextInterface = this.useContextWrapper
            ? { context: this.context }
            : this.context;

        return {
            data: {
                ...clonedProcessVariables,
                ...contextInterface,
            },
        };
    }

    @computed get nextTasks(): Array<any> | undefined {
        return this.updateItemLoader.data;
    }

    @action setUseContextWrapper(value: boolean) {
        this.useContextWrapper = value;
    }

    @action setContext(context?: any) {
        this.context = context;
    }

    @action clearContext() {
        this.context = null;
    }

    @computed get getContext() {
        return this.context;
    }

    @computed get hasContext() {
        return !_.isNil(this.context) || !_.isEmpty(this.context);
    }
}

export const ProcessStoreProvider = new EntityProvider(ProcessStoreClass);
