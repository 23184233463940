import { computed } from 'mobx';
import { LegalEntity, Person } from 'src/generated-api-client';
import {
    borrowersApi,
    bpmsResourcesManagerApi,
} from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/BorrowersStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type BorrowersFilter = {
    search?: string;
    blackList?: boolean;
};

type BorrowerItemDetails = LegalEntity | Person;
export class BorrowersStoreClass extends BasicStore<
    BorrowerItemDetails,
    BorrowersFilter
> {
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-12-borrower.json';

    itemEditFormKey = 'embedded:deployment:forms/bo/bo-31-edit-borrower.json';

    pager = new Pager();
    api: BasicStoreApi<BorrowerItemDetails> = {
        loadList: async (...args: any[]) => {
            const options = RequestHelper.getOptionsFromArgs(args);
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                borrowersApi.findAllBorrowers(
                    this.pager?.page,
                    this.pager.size,
                    InitialFilters.defaultSort,
                    this.filterCriteria.filter.blackList,
                    this.filterCriteria.filter.search,
                    options,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },
        updateItem: (id, data, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                borrowersApi.updateBorrower(Number(id), data, options),
            );
        },
        loadItemForm: (formKey: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(formKey, options),
            );
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                borrowersApi.getBorrower(id, options),
            );
        },
    };

    filterCriteria: FilterCriteria<BorrowersFilter> = new FilterCriteria({});
    @computed get hasFilter() {
        return Boolean(this.filterCriteria.filter.search);
    }
}

export const BorrowersStore = new BorrowersStoreClass();
