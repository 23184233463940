import { ProcessDefinitionRepresentation } from 'src/generated-api-client';
import { processDefinitionManagerApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProcessDefinitionsStoreClass extends BasicStore<ProcessDefinitionRepresentation> {
    api: BasicStoreApi<ProcessDefinitionRepresentation> = {
        loadList: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                processDefinitionManagerApi.listStartableByUser(options),
            );
        },
        loadItem: async (key: string) => {
            if (!this.getProcessDefinition(key)) {
                await this.loadList();
            }

            const processDefinition = this.getProcessDefinition(key);

            if (!processDefinition) {
                throw new Error(
                    `Process definition with key ${key} is not found`,
                );
            }

            return processDefinition;
        },
    };

    getProcessDefinition(key: string) {
        return this.list.find(
            (processDefinition) => processDefinition.key === key,
        );
    }
}

export const ProcessDefinitionsStore = new ProcessDefinitionsStoreClass();
