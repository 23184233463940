import { computed } from 'mobx';
import moment from 'moment';
import { LoanBrief } from 'src/generated-api-client';
import { bpmsResourcesManagerApi, loansApi } from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/LoansStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LoansFilter = {
    search?: string;
    issueDateFrom?: moment.Moment;
    issueDateTo?: moment.Moment;
    status?: string[];
};

export class LoansStoreClass extends BasicStore<LoanBrief> {
    itemPathKey = 'loanId';
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-03-loan.json';
    pager = new Pager();
    api: BasicStoreApi<LoanBrief> = {
        loadList: async (...args: any[]) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            const response = await RequestHelper.unwrapFromAxiosPromise(
                loansApi.findAllLoans(
                    this.pager?.page,
                    this.pager?.size,
                    InitialFilters.defaultSort,
                    this.filterCriteria.filter.status,
                    this.filterCriteria.filter.issueDateFrom?.toISOString(true),
                    this.filterCriteria.filter.issueDateTo?.toISOString(),
                    this.filterCriteria.filter.search,
                    options,
                ),
            );

            this.pager.setTotal(response.totalElements || 0);

            return response.content || [];
        },
        loadItem: (id, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getLoan(id, options),
            );
        },
        loadItemForm: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(
                    this.itemDetailsFormKey,
                    options,
                ),
            );
        },
    };

    filterCriteria = new FilterCriteria<LoansFilter>();

    @computed get hasFilter() {
        return (
            Boolean(this.filterCriteria.filter.status) ||
            (Boolean(this.filterCriteria.filter.issueDateFrom) &&
                Boolean(this.filterCriteria.filter.issueDateTo)) ||
            Boolean(this.filterCriteria.filter.search)
        );
    }
}

export const LoansStore = new LoansStoreClass();
