import { computed } from 'mobx';
import { CollectionBrief } from 'src/generated-api-client';
import {
    bpmsResourcesManagerApi,
    collectionsApi,
} from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/CollectionsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type CollectionsFilter = {
    search?: string;
    status?: string[];
    issueDateFrom?: moment.Moment;
    issueDateTo?: moment.Moment;
};

export class CollectionsStoreClass extends BasicStore<CollectionBrief> {
    itemDetailsFormKey =
        'embedded:deployment:forms/bo/bo-24-collection-info.json';

    pager = new Pager();
    api: BasicStoreApi<CollectionBrief> = {
        loadList: async (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            const response = await RequestHelper.unwrapFromAxiosPromise(
                collectionsApi.findAllCollections(
                    this.pager?.page,
                    this.pager?.size,
                    InitialFilters.defaultSort,
                    this.filterCriteria.filter.status,
                    this.filterCriteria.filter.issueDateFrom?.toISOString(true),
                    this.filterCriteria.filter.issueDateTo?.toISOString(),
                    this.filterCriteria.filter.search,
                    options,
                ),
            );

            this.pager.setTotal(response.totalElements || 0);

            return response.content || [];
        },
        loadItem: (id, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                collectionsApi.getCollection(id, options),
            );
        },
        loadItemForm: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(
                    this.itemDetailsFormKey,
                    options,
                ),
            );
        },
    };

    filterCriteria = new FilterCriteria<CollectionsFilter>();

    @computed get hasFilter() {
        return (
            Boolean(this.filterCriteria.filter.search) ||
            Boolean(this.filterCriteria.filter.status) ||
            (Boolean(this.filterCriteria.filter.issueDateFrom) &&
                Boolean(this.filterCriteria.filter.issueDateTo))
        );
    }
}

export const CollectionsStore = new CollectionsStoreClass();
